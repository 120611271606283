import React, { memo, useCallback, useContext, useState, useMemo, useRef, useEffect } from 'react'
import {
  PrimaryButton,
  SecondaryButton,
  HeadingLarge,
  BodyText,
  TextArea,
  BannerNotification,
  Spinner,
  CheckboxInput
} from '@hm-group/fabric-components'
import './CICSurveyComponent.scss'
import { useHistory, useLocation } from 'react-router-dom';
import { getBrandImagebyID, getDisplayBrandNamebyID, getTouchPoint, getkeyvalueText } from '../../util/helperfunctions'
import { submitCICFeedback } from '../../Services/DataService'
import { EVENTACTION, EVENTOUTCOME, commonConstants } from '../../util/constants'
import { UserContext } from '../../context/userContext';

/**
 * CICFeedback renders a checkbox input for a specific feedback option.
 *
 * @param {object} props - The properties object.
 * @param {string} props.value - The value associated with the feedback option.
 * @param {function} props.handleSelect - Callback to handle selection changes.
 * @param {boolean} props.isChecked - Whether the checkbox is checked.
 * @param {string} props.label - The label for the checkbox.
 *
 * @returns {JSX.Element} The rendered checkbox input.
 */
const CICFeedback = memo(({ value, handleSelect, isChecked, label }) => (
  <CheckboxInput
    className="cicCheckbox"
    labels={{
      errorLabel: 'Error message',
      label: label,
    }}
    isChecked={isChecked}
    name={`Checkbox-${value}`}
    onChange={(e) => handleSelect(value, e.target.checked)}
  />
))

/**
 * CICSurveyComponent renders a feedback survey form with checkboxes and a text area.
 *
 * @param {object} props - The properties object.
 * @param {function} props.onClose - Callback to handle closing the survey component.
 * @param {object} props.payloadData - Additional data to be sent with the feedback.
 *
 * @returns {JSX.Element} The rendered CICSurveyComponent.
 */

const CICSurveyComponent = ({ onClose, payloadData, sendCICAnalyticsData }) => {
  const location = useLocation()
  const isshowkey = location.state && location.state.isshowkey
  const {lslKeyValue} = useContext(UserContext)
  const textAreaRef = useRef(null)
  const cicFeedbackRef = useRef(null)
  const history = useHistory()

  const CICOptions = useMemo(
    () => [
      'shopConcern',
      'personalConcern',
      'emailConcern',
      'interestConcern',
      'cicmovedothercountry',
      'cicselectedwrongcountry',
      'dataConcern',
      't&cConcern',
    ],
    [],
  )

  const [feedbackState, setFeedbackState] = useState({
    cicReasons: [],
    comment: '',
    showError: false,
    showApiError: false,
  })
  const [isLoading, setIsLoading] = useState(false)

  /**
   * Handles changes in the comment text area.
   *
   * @param {object} e - The event object.
   */
  const handleCommentChange = useCallback((e) => {
    setFeedbackState((prevState) => ({
      ...prevState,
      comment: e.target.value,
    }))
  }, [])

  /**
   * Handles selection changes of checkboxes.
   *
   * @param {string} value - The value of the checkbox.
   * @param {boolean} isChecked - Whether the checkbox is checked.
   */
  const handleSelect = useCallback((value, isChecked) => {
    setFeedbackState((prevState) => ({
      ...prevState,
      cicReasons: isChecked ? [...prevState.cicReasons, value] : prevState.cicReasons.filter((item) => item !== value),
      showError: false,
    }))
  }, [])

  /**
   * Handles the continue button click event to submit feedback.
   */
  const handleContinue = async () => {
    if (feedbackState.cicReasons.length === 0) {
      setFeedbackState((prevState) => ({ ...prevState, showError: true }))
      return
    }
    setIsLoading(true)

    const payload = {
      cicReasons: feedbackState.cicReasons,
      additionalFeedback: feedbackState.comment,
      ...payloadData,
      surveyId: 'missyouLabel',
    }

    try {
      const response = await submitCICFeedback(payload)
      if (response.status === 200) {
        sendCICAnalyticsData(EVENTACTION.cicSurveySubmit,EVENTOUTCOME.success)
        history.push({ pathname: '/dashboard', state: { isFeedbackSubmitted: true } })  
      } else {
        setFeedbackState((prevState) => ({ ...prevState, showApiError: true }))
        sendCICAnalyticsData(EVENTACTION.cicSurveySubmit,EVENTOUTCOME.failure)
      }
    } catch (err) {
      setFeedbackState((prevState) => ({ ...prevState, showApiError: true }))
      sendCICAnalyticsData(EVENTACTION.cicSurveySubmit,EVENTOUTCOME.failure)
    } finally {
      setIsLoading(false)
    }
  }

  /**
   * Retrieves the label text for a given key.
   *
   * @param {string} key - The key for the label.
   * @returns {string} The label text.
   */
  const getLabel = useCallback(
    (key) => {
      return getkeyvalueText(
        ['cicmovedothercountry', 'cicselectedwrongcountry'].includes(key) ? 'CICFeedBack' : 'SecondaryfeedbackChoices',
        key,
        isshowkey,
        lslKeyValue.lslKeyData,
      ).replace('{Brand}', getDisplayBrandNamebyID(payloadData.brand))
    },
    [isshowkey, lslKeyValue.lslKeyData],
  )

  /**
   * Scrolls the TextArea into view when it gains focus.
   */
  useEffect(() => {
    if (textAreaRef.current && cicFeedbackRef.current && [
      commonConstants.OS_ANDROID,
    ].includes(getTouchPoint())) {

      const handleFocus = () => {

        const defaultMargin = '33vh'; // 33% of viewport height
        // Scroll the text area into view on Android devices

        cicFeedbackRef.current.style.marginBottom = defaultMargin;
        textAreaRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

      };

      let blurTimeout;
      const handleBlur = () => {
        if (cicFeedbackRef.current) {
          // Delay resetting the margin added to prioritize the click event on submit button
          blurTimeout = setTimeout(() => {
            cicFeedbackRef.current.style.marginBottom = '0px';
          }, 0);
        }
      };

      const textAreaElement = textAreaRef.current;

      if (textAreaElement) {
        textAreaElement.addEventListener('focus', handleFocus);
        textAreaElement.addEventListener('blur', handleBlur);
      }

      return () => {
        if (textAreaElement) {
          textAreaElement.removeEventListener('focus', handleFocus);
          textAreaElement.removeEventListener('blur', handleBlur);
          clearTimeout(blurTimeout);
        }
      };
    }
  }, [textAreaRef, cicFeedbackRef]);

  return (
    <div ref={cicFeedbackRef}>
      {isLoading ? (
        <div className="loader_section">
          <Spinner color="black" isRendered={isLoading} />
        </div>
      ) : (
        ''
      )}
     
      <div className={isLoading ? 'disabled' : ''}>
      <div className="brand__img">
        <img className='logoImage' src={getBrandImagebyID(payloadData.brand)} alt={payloadData.brand}></img>
      </div>
        { (
          <BannerNotification
            closeLabel="Close"
            message={getkeyvalueText(
              feedbackState.showApiError ?'accessPersonalData' : 'ManageServices',
              feedbackState.showApiError ? 'accessdataerrormsgLabel' : 'custreqsubmitLabel',
              isshowkey,
              lslKeyValue.lslKeyData,
            )}
            severity={feedbackState.showApiError ? 'error' : 'success'}
          />
        )}
        <HeadingLarge Tag="h1" className="cicFeedbackHeading" tabIndex={0}>
          {getkeyvalueText('FeedBack', 'missyouLabel', isshowkey, lslKeyValue.lslKeyData)}
        </HeadingLarge>
        <BodyText Tag="p" className="thanksText" tabIndex={0}>
          {getkeyvalueText('FeedBack', 'letusknowreasonLabel', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <p>
        <BodyText Tag="p" className="labelHeading" tabIndex={0}>
          {getkeyvalueText('CICFeedBack', 'cicselectallanswers', isshowkey, lslKeyValue.lslKeyData)}
        </BodyText>
        <BodyText className="asterisk">*</BodyText>
        </p>
        {CICOptions.map((item, index) => (
          <div key={index} className="checkBox">
            <CICFeedback
              value={item}
              handleSelect={handleSelect}
              isChecked={feedbackState.cicReasons.includes(item)}
              label={getLabel(item)}
            />
          </div>
        ))}
        {feedbackState.showError ? (
          <BodyText Tag="p" className="errorText">
            {getkeyvalueText('A&PFeedback', 'apselectoption', isshowkey, lslKeyValue.lslKeyData)}
          </BodyText>
        ) : ''}
        <TextArea
          className="textArea"
          inputMode="text"
          label={getkeyvalueText('commonKey', 'anythingelselabel', isshowkey, lslKeyValue.lslKeyData)}
          maxLength={255}
          name="text-area"
          value={feedbackState.comment}
          onChange={handleCommentChange}
          placeholder={getkeyvalueText('FeedBack', 'donotenternumbersemailLabel', isshowkey, lslKeyValue.lslKeyData)}
          tabIndex={0}
          aria-label="Additional comments"
          ref={textAreaRef}
        />
        <div className="actionButton">
          <PrimaryButton onClick={handleContinue} tabIndex={0}>
            {getkeyvalueText('commonKey', 'submitbtn', isshowkey, lslKeyValue.lslKeyData)}
          </PrimaryButton>
          <SecondaryButton onClick={() => onClose(false)} tabIndex={0}>
            {getkeyvalueText('commonKey', 'nolongerparticipate', isshowkey, lslKeyValue.lslKeyData)}
          </SecondaryButton>
        </div>
      </div>
    </div>
  )
}

export default CICSurveyComponent
