export const EmailConsentTexts = [

    {
        key:"HM",
        keyText : {
        "subText1" : "Yes, I want to receive personalized marketing material from H&M, a brand of the H&M Group. I confirm I’m ",
        "subText2" : " years or older. I consent to let H&M Group, process my personal data to provide me with personalised marketing material in accordance with the relevant "
        }
    },
    {
        key:"notHM",
        keyText : {
        "subText1" : "Yes, I want to receive promotions from ",
        "subText2" : " a brand of the H&M Group. I confirm I’m ",
        "subText3" : " years+. I consent to let H&M Group, process my personal data to provide me with personalised promotions in my social media, email and SMS in accordance with the relevant "
        }
    }
]