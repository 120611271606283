import React, { useState, useContext } from 'react'
import './EditSubscriptions.scss'
import { useHistory } from 'react-router-dom'
import {
  getkeyvalueText,
  isFutureCommerceMarket,
} from '../../util/helperfunctions'
import {
  MetaText,
  BannerNotification,
  ListGrid,
} from '@hm-group/fabric-components'
import { getCustomerDetails, updateSubscription } from '../../Services/DataService'
import { AnalyticsContext } from '../../context/analyticsContext'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID } from '../../util/constants'
import { CustomerContext } from '../../context/customerDetailsContext'
import { PrivacyLink } from '../CommonComponents/PrivacyLink'
import { EmailMarketing } from './Components/EmailMarketing'
import { DirectMailMarketing } from './Components/DirectMailMarketing'
import { UserContext } from '../../context/userContext'
import { SubscriptionHeader } from './Components/subscriptionHeader'
import { ActionButtons } from './Components/SubscriptionActionButtons'

const EditSubscription = () => {
  const history = useHistory()
  const {lslKeyValue} = useContext(UserContext);
  const { customer, updateCustomer } = useContext(CustomerContext)

  const [isInputError, setIsInputError] = useState(false)
  const [showLoader, setShowLoader] = useState(false)
  const isshowkey = false // check this later
  const {
    businessPartnerId,
    emailAddress,
    corporateBrandId,
    countryCode,
    onlineOMSCustomerIdentifier,
    hmCatalogueSubscription,
    frequencyFashionNews,
    frequencyTextFashionNews,
    clubRole,
  } = customer

  const isNews =
    frequencyFashionNews && frequencyFashionNews.toLowerCase() === 'reg'
      ? true
      : (clubRole === 'z003' && frequencyTextFashionNews && frequencyTextFashionNews.toLowerCase() === 'regularly') === true

  const [userIsSubscribedToFashionNews, setUserIsSubscribedToFashionNews] = useState(isNews || false)
  const [userIsSubscribedToClubNews, setUserIsSubscribedToClubNews] = useState(hmCatalogueSubscription || false)
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)

  const updateAnalytics = (eventAction, eventOutcome) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.updateSubscription,
      page: PAGE.updateSubscriptionPage,
      eventAction,
      eventOutcome,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
    })
  }

  const handleRadioInput = (e) => {
    switch (e.target.id) {
      case 'emailmarketing-opt-in':
        setUserIsSubscribedToFashionNews(true);
        break;
      case 'emailmarketing-opt-out':
        setUserIsSubscribedToFashionNews(false);
        break;
      case 'mailmarketing-opt-in':
        setUserIsSubscribedToClubNews(true);
        break;
      case 'mailmarketing-opt-out':
        setUserIsSubscribedToClubNews(false);
        break;
      default:
        break;
    }
  };
  

  const getEventAction = () => {
    let eventAction = ''
    if (isNews !== userIsSubscribedToFashionNews) {
      eventAction = userIsSubscribedToFashionNews ? EVENTACTION.newsletterEditOptin : EVENTACTION.newsletterEditOptout
    }
    if (hmCatalogueSubscription !== userIsSubscribedToClubNews) {
      eventAction = userIsSubscribedToClubNews
        ? `${eventAction ? `${eventAction}/` : ''}${EVENTACTION.directMailEditOptin}`
        : `${eventAction ? `${eventAction}/` : ''}${EVENTACTION.directMailEditOptout}`
    }
    return eventAction
  }

  // TODO : will optimize this and remove this function and update analytics in handleConfirmAction
  const updateCustomerDetails = async () => {
    const tempData = {
      bpid: businessPartnerId,
      brandid: corporateBrandId,
      countrycode: countryCode,
    }

    try {
      const apiResponse = await getCustomerDetails(tempData)
      if (apiResponse.status === 200) {
        const { data } = apiResponse
        // this line is temprory will be remove after creating club role constants
        apiResponse.data.clubRole = apiResponse.data.clubRole.toLowerCase()
        updateCustomer(data)
      }
    } catch (err) {
      // handle error
    }
  }

  const handleConfirmAction = async () => {
    const eventAction = getEventAction()
    if (!eventAction) return

    setIsInputError(false)
    setShowLoader(true)

    const editRequestParam = {
      fashionNewsSubscriber: userIsSubscribedToFashionNews,
      brandId: corporateBrandId,
      countryCode: countryCode,
      customerId: onlineOMSCustomerIdentifier,
      email: emailAddress.toLowerCase(),
    }

    if (corporateBrandId === '0') {
      editRequestParam.catalogSubscriber = userIsSubscribedToClubNews
    }

    if (isFutureCommerceMarket(brandNameFromID[corporateBrandId], countryCode) || corporateBrandId === '3') {
      editRequestParam.customerId = businessPartnerId
    }

    try {
      const subscriptionData = await updateSubscription(editRequestParam)
      if (subscriptionData.status === 200) {
        // update context here
        setShowLoader(false)

        updateAnalytics(eventAction, EVENTOUTCOME.success)

        await updateCustomerDetails()
        history.push({ pathname: '/ManageServices', state: { isEditRequested: true } })
      } else {
        setIsInputError(true)
        setShowLoader(false)
        updateAnalytics(eventAction, EVENTOUTCOME.failure)
      }
    } catch (err) {
      setIsInputError(true)
      setShowLoader(false)

      updateAnalytics(eventAction, EVENTOUTCOME.failure)
    }
  }

  const handleCancel = () => {
    updateAnalytics(EVENTACTION.updateSubscriptionCancelled, EVENTOUTCOME.success)
    history.push({ pathname: '/ManageServices' })
  }

  return (
    <ListGrid numberOfColumns={{ xsmall: 1, small: 1, medium: 1, xlarge: 1 }}>
      <div className="edit__subsciptions">
        {isInputError && (
          <BannerNotification
            closeLabel="Close"
            message={getkeyvalueText('editSubscriptions', 'updatesubserrormsgLabel', isshowkey, lslKeyValue.lslKeyData)}
            severity="error"
          />
        )}
         {/* header */}
        <SubscriptionHeader corporateBrandId={corporateBrandId} lslKeyValue={lslKeyValue} countryCode = {countryCode}/>

        <div className="subscriptions__holder">
          {/* email marketing */}
          <EmailMarketing
            userIsSubscribedToFashionNews={userIsSubscribedToFashionNews}
            handleRadioInput={handleRadioInput}
            lslKeyValue={lslKeyValue}
            corporateBrandId={corporateBrandId}
            countryCode = {countryCode}
          />
         
          {/* direct mail market only for HM */}
          {corporateBrandId == '0' ? (
            <DirectMailMarketing
              userIsSubscribedToClubNews={userIsSubscribedToClubNews}
              lslKeyValue={lslKeyValue}
              handleRadioInput={handleRadioInput}
            />
          ) : (
            ''
          )}

          <MetaText className="subscription-disclaimer">
            {getkeyvalueText('editSubscriptions', 'emailsubsdisclaimerLabel', isshowkey, lslKeyValue.lslKeyData)}
          </MetaText>
          <br />
          <MetaText className="subscription-disclaimer">
            {
              getkeyvalueText('editSubscriptions', 'emailsubsadditionalInfo', isshowkey, lslKeyValue.lslKeyData).split(
                '{privacylink}',
              )[0]
            }
          </MetaText>

          {/*privacy link and text*/}
          <PrivacyLink corporateBrandId={corporateBrandId} countryCode={countryCode} lslKeyValue={lslKeyValue} />
        </div>

         {/*Save and Cancel buttons*/}
         <ActionButtons
          handleCancel={handleCancel}
          handleConfirmAction={handleConfirmAction}
          lslKeyValue={lslKeyValue}
          showLoader={showLoader}
        />
      </div>
    </ListGrid>
  )
}

export default EditSubscription
