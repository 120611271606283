import axios from 'axios'
import * as qs from 'query-string';
import { APIHOST, Country } from '../util/util';
import { getSortedArray } from '../util/helperfunctions'
import { brandNameFromID } from '../util/constants';


export const getAllBPidForEmailogin = (postData) => {

    let tempData = {
        "userName": localStorage.getItem("userName")
    };
    const emailID = Object.keys(postData).length !== 0 ? postData : tempData;
    const authCode = localStorage.getItem("accesstoken");
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            // .get('https://jsonplaceholder.typicode.com/todos/1')
            // .get('/getAllBPidForEmail/' + emailID,{
            .post('/getAllBPidForEmail', qs.stringify(emailID), {
                headers: headers,
            })
    )
}

// export const getTranslatedTextFromAz = (text, preferredLanguage) => {
//     const { v4: uuidv4 } = require('uuid');
//     let key = "498951803e504ea9bb339c90337b1217";
//     let endpoint = "https://api.cognitive.microsofttranslator.com/";

//     // location, also known as region.
//     // required if you're using a multi-service or regional (not global) resource. It can be found in the Azure portal on the Keys and Endpoint page.
//     let location = "westeurope";

//     return (
//         axios({
//         baseURL: endpoint,
//         url: '/translate',
//         method: 'post',
//         headers: {
//             'Ocp-Apim-Subscription-Key': key,
//              // location required if you're using a multi-service or regional (not global) resource.
//             'Ocp-Apim-Subscription-Region': location,
//             'Content-type': 'application/json',
//             'X-ClientTraceId': uuidv4().toString()
//         },
//         params: {
//             'api-version': '3.0',
//             'from': preferredLanguage,
//             'to': ['en']
//         },
//         data: [{
//             'text': text
//         }],
//         responseType: 'json'
//     })
//     )
// }

// Example method for detecting the language of text
// async function languageDetection(client) {

//     const languageInputArray = [
//         "Ce document est rédigé en Français."
//     ];
//     const languageResult = await client.detectLanguage(languageInputArray);

//     languageResult.forEach(document => {
//         console.log(`ID: ${document.id}`);
//         console.log(`\tPrimary Language ${document.primaryLanguage.name}`)
//     });
// }


export const sendAnalytics = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/dataanalytics', qs.stringify(postData), {
                headers: headers,
            })
    )

}



export const getCustomerDetails = (requestData) => {
    let currentCountry = localStorage.getItem("current_country");
    currentCountry = currentCountry.split("|")[0];
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
        'liveCntry': currentCountry
    }
    return (
        axios
            .post('/customerdetails', qs.stringify(requestData), {
                headers: headers,
            })
    )
}

export const sendotpemail = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/sendotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const verifyotpemail = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/verifyotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const getLSLData = (lslParam) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/lslstore', qs.stringify(lslParam), {
                headers: headers
            })
    )

}

export const resetPassword = (resetParam) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/forgotPassword', qs.stringify(resetParam), {
                headers: headers,
            })
    )

}

export const verifyParam = (paramData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/doParamCheck', qs.stringify(paramData), {
                headers: headers
            })
    )
}

export const updateSubscription = (editRequestParam) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/updateSubscription', qs.stringify(editRequestParam), {
                headers: headers,
            })
    )

}

export const sendapotpemail = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/sendapotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const sendloginotpemail = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/sendloginotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const verifyapotp = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/verifyapotp', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const verifyloginotp = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/verifyloginotp', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const downloadAPData = (downloadRequest, fileType) => {
    // https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf

    if (fileType == 'xml_button') {

        return (
            axios.post('/downloadAPDataXML', qs.stringify(downloadRequest), {
                responseType: 'blob',
            })
        )
    } else {
        return (
            axios.post('/downloadAPDataPDF', qs.stringify(downloadRequest), {
                responseType: 'blob',
            })
        )
    }

    // axios({
    //     url: 'http://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', //your url
    //     method: 'POST',
    //     'Access-Control-Allow-Origin': '*',
    //     responseType: 'blob', // important
    // })
}

export const checkFeedbackStatus = (payload) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',

    }
    return (
        axios
            .post(`/checkAPFeedbackStatus`, qs.stringify(payload), {
                headers: headers,
            })
    )
}

export const submitAPFeedback = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return (
        axios
            .post(`/submitAPFeedback`, JSON.stringify(payload), {
                headers: headers,
            })
    )
}

export const submitCICFeedback = (payload) => {
    const headers = {
        'Content-Type': 'application/json',
    }
    return (
        axios
            .post(`/surveys/cic`, JSON.stringify(payload), {
                headers: headers,
            })
    )
}

export const sendcicotpemailrequest = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/sendcicotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const verifycicotpemailrequest = (postData) => {
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/verifycicotpemail', qs.stringify(postData), {
                headers: headers,
            })
    )

}

export const requestPersonalData = (requestData) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/requestPersonalData', qs.stringify(requestData), {
                headers: headers,
            })
    )
}

export const deletePersonalData = (requestData) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
    }
    return (
        axios
            .post('/initiateCustomerCleansing', qs.stringify(requestData), {
                headers: headers,
            })
    )
}



export const doVerifyBrand = (requestData) => {

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return (
        axios
            .post('/v2/verifyBrand', qs.stringify(requestData), {
                headers: headers,
            })
    )
}



/**
 * Transform input data by grouping items under their respective corporate brands and sorting them by country code.
 * @param {Array} inputData - An array of objects representing items with corporate brand IDs.
 * @param {String} sourceBPID - The business partner ID of the source brand.
 * @returns {Array} An array of objects containing corporate brand details and their respective items sorted by country code.
 */

export const transformData = (inputData, sourceBPID) => {
    // Define a map to store brand items
    const brandMap = new Map();

    // Iterate over the input data
    inputData.forEach(item => {
        const brandId = item.corporateBrandId;
        const brandName = brandNameFromID[brandId];

        // Check if the brand already exists in the map
        if (brandMap.has(brandId)) {
            // If it exists, push the item to its items array
            brandMap.get(brandId).items.push(item)
            // sort the items array by countryCode
            getSortedArray(brandMap.get(brandId).items, { businessPartnerId: sourceBPID }, 'countryCode');
        } else {
            // If it doesn't exist, create a new entry in the map
            brandMap.set(brandId, {
                brandId: brandId,
                brandName: brandName,
                items: [item]
            });
        }
    });

    // Return the values of the map as an array
    return Array.from(brandMap.values());
}
