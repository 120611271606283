import React, { useState, useContext, useCallback } from 'react'
import './PersonalData.scss'
import AccessPersonalData from './components/AccessPersonalData'
import { PrimaryButton, SecondaryButton, HeadingLarge, ListGrid, BannerNotification, Spinner } from '@hm-group/fabric-components'
import { useHistory, useLocation } from 'react-router-dom'
import _ from 'lodash'
import {
  getBrandIdbyName,
  getCountryNamebyCode,
  getDisplayBrandNamebyID,
  getkeyvalueText,
  getBrandImagebyID,
} from '../../util/helperfunctions'
import { getAllBPidForEmailogin, getCustomerDetails, requestPersonalData } from '../../Services/DataService'
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID } from '../../util/constants'
import { AnalyticsContext } from '../../context/analyticsContext'
import { CustomerContext } from '../../context/customerDetailsContext'
import { CustomerDataContext } from '../../context/customerDataContext'
import { UserContext } from '../../context/userContext'

const PersonalDataHolder = () => {
  const location = useLocation()
  const {lslKeyValue} = useContext(UserContext)
  const { customer, updateCustomer } = useContext(CustomerContext)
  const { customerData,updateCustomerData } = useContext(CustomerDataContext)
  const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext)
  const { emailid = localStorage.getItem('userName') } = (lslKeyValue && lslKeyValue.plainres) || {};

  const [showLoader, setShowLoader] = useState(false)
  const [error, setError] = useState(false)

  const { businessPartnerId, emailAddress, corporateBrandId, countryCode, onlineOMSCustomerIdentifier, clubRole } =
    customer

  const { guestBusinessPartnerId } = customerData

  const brand = brandNameFromID[corporateBrandId]
  const history = useHistory()
  const { isGuest = false } = location.state || {};

  const isShowKey = false // temporary

  const setAnalyticsData = useCallback(
    (eventOutcome, eventAction) => {
      updateAnalyticsData({
        ...analyticsData,
        managedCountry: countryCode,
        managedBrand: corporateBrandId,
        eventType: EVENTTYPE.ap,
        page: PAGE.accessPersonalDataPage,
        eventAction,
        eventOutcome,
        managedBPID: businessPartnerId,
        managedCustomerType: clubRole,
      })
    },
    [analyticsData, countryCode, corporateBrandId, businessPartnerId, clubRole, updateAnalyticsData],
  )

  // TODO :will remove this function and use the one from context
  const updateCustomerDetails = async () => {
    const tempData = {
      bpid: businessPartnerId,
      brandid: corporateBrandId,
      countrycode: countryCode,
    }

    try {
      const apiResponse = await getCustomerDetails(tempData)
      if (apiResponse.status === 200) {
        const { data } = apiResponse
        // this line is temprory will be remove after creating club role constants
        apiResponse.data.clubRole = apiResponse.data.clubRole.toLowerCase()
        updateCustomer(data)

        //TODO :  need to call this to update guest customer data, will find a better way to do this
        const tempData1 = {
          userName: emailid,
          entryType: '002',
        }
         const customerDataResponse = await getAllBPidForEmailogin(tempData1)
    
        if (customerDataResponse.status === 200 && customerDataResponse.data.customers.length > 0) {
          const customerDataObject = customerDataResponse.data.customers.find(customer => customer.businessPartnerId === businessPartnerId);
          if (customerDataObject) {
            updateCustomerData(customerDataObject)
          }
        }
      }
    } catch (err) {
     // handle error
    }
  }

  const handleAPRequest = async () => {
    setShowLoader(true)
    const accessRequestParams = {
      customerId: onlineOMSCustomerIdentifier,
      businessPartnerId: businessPartnerId,
      countryCode,
      brandId: corporateBrandId,
      emailid: emailAddress,
      customerType: clubRole,
      preferredLanguage: lslKeyValue.preferredLanguage,
      preferredCountry: countryCode,
    }

    if (businessPartnerId && guestBusinessPartnerId && isGuest) {
      accessRequestParams.customerType = 'z000'
      accessRequestParams.businessPartnerId = guestBusinessPartnerId
    }

    try {
      const apiResponse = await requestPersonalData(accessRequestParams)
      if (apiResponse.status === 200) {
        setAnalyticsData(EVENTOUTCOME.success, EVENTACTION.apDataRequested)
        await updateCustomerDetails()
        history.push({ pathname: '/ManageServices', state: { isDataRequested: true } })
      } else {
        setAnalyticsData(EVENTOUTCOME.failure, EVENTACTION.apDataRequested)
        setError(true)
      }
    } catch (err) {
      setAnalyticsData(EVENTOUTCOME.failure, EVENTACTION.apDataRequested)
      setError(true)
    } finally {
      setShowLoader(false)
    }
  }

  return (
    <>
      <ListGrid
        numberOfColumns={{
          xsmall: 1,
          small: 1,
          medium: 1,
          xlarge: 1,
        }}
      >
        <div className="personal__data">
          <div className="brandLogo__title_section">
            <div className="brand__img">
              <img className="brand_puff_logo" src={getBrandImagebyID(corporateBrandId)} alt={brand} />
            </div>

            {error ? (
              <div className="apiErrorBanner">
                <BannerNotification
                  closeLabel="Close"
                  message={getkeyvalueText(
                    'accessPersonalData',
                    'accessdataerrormsgLabel',
                    isShowKey,
                    lslKeyValue.lslKeyData,
                  )}
                  severity="error"
                />
              </div>
            ) : null}
            <div className="edit__text">
              <HeadingLarge Tag="h1" className="edit__div">
                {getkeyvalueText('accessPersonalData', 'requestpersonaldataLabel', isShowKey, lslKeyValue.lslKeyData)
                  .replace('{Brand}', getDisplayBrandNamebyID(getBrandIdbyName(brand)))
                  .replace('{Market}', getCountryNamebyCode(countryCode, isShowKey, lslKeyValue.lslKeyData))}
              </HeadingLarge>
            </div>
          </div>
          <div className="data__text_holder">
            <AccessPersonalData />
          </div>
          <div className="cta__buttons">
            <PrimaryButton className="submit__btn" isDisabled={showLoader || isShowKey} onClick={handleAPRequest}>
              {getkeyvalueText('accessPersonalData', 'RequestaccessbtnLabel', isShowKey, lslKeyValue.lslKeyData)}
            </PrimaryButton>
            {showLoader && (
              <div className="loader_area">
                <Spinner color="#ffff" />
              </div>
            )}
            <SecondaryButton
              className="cancel__btn"
              data-testid="cancel__btn"
              isDisabled={showLoader || isShowKey}
              onClick={() => {
                setAnalyticsData(EVENTOUTCOME.success, EVENTACTION.apRequestPageCancel)
                history.push({ pathname: '/ManageServices' })
              }}
            >
              {getkeyvalueText('commonKey', 'cancelbtnLabel', isShowKey, lslKeyValue.lslKeyData)}
            </SecondaryButton>
          </div>
        </div>
      </ListGrid>
    </>
  )
}

export default PersonalDataHolder
