

export const EVENTTYPE = Object.freeze({
    updateSubscription: 'EDIT_SUBSCRIPTION',
    login: 'LOGIN',
    apLogin: 'A&P',
    ap: 'A&P',
    cic: 'CIC',
    cicVerification: 'CIC',
    apDownload: 'A&P_DOWNLOAD',
    apDownloadManage: 'A&P_DOWNLOAD_MANAGE',
    manageService: 'MANAGE_SERVICES',
    overview: 'OVERVIEW',
    apSurvey: 'A&P_SURVEY',
    cicSurvey: 'CIC_SURVEY',
});


export const PAGE = Object.freeze({
    loginPage: 'LOGIN_PAGE',
    updateSubscriptionPage: 'UPDATE_SUBSCRIPTION_PAGE',
    apLoginPage: 'A&P_LOGIN_PAGE',
    confirmLoginPage: 'LOGIN_PAGE',
    deleteAccountPage: 'CIC_MANAGE_PAGE',
    accessPersonalDataPage: 'A&P_INFO_PAGE',
    cicAreYouSurePage: 'CIC_ARE_YOU_SURE_PAGE',
    apDownloadPage: 'A&P_DOWNLOAD_PAGE',
    apDownloadManagePage: 'A&P_MANAGE_PAGE',
    cicInfoPage: 'CIC_INFO_PAGE',
    overviewPage: 'OVERVIEW_PAGE',
    apSurveyPage: 'A&P_SURVEY_PAGE',
    cicSurveyPage: 'CIC_SURVEY_PAGE',
});

export const EVENTACTION = Object.freeze({
    //Cic
    cicInfoPageContinue: 'CIC_INFO_PAGE_CONTINUE',
    cicInfoPageCancel: 'CIC_INFO_PAGE_CANCEL',
    cicVerificationPageCancel: 'CIC_ARE_YOU_SURE_CANCEL',
    cicOtpSent: 'CIC_OTP_SENT',
    cicOtpVerified: 'CIC_OTP_VERIFIED',
    cicOtpVerifiedError: 'CIC_OTP_VERIFIED_ERROR',
    deletePersonalDataReadMore: 'CIC_MANAGE_CONTINUE',
    deletePersonalDataInitiated: 'CIC_DATA_DELETION_REQUEST_DELETE',

    //login A&P
    LoginApPageDataReady: 'A&P_LOGIN_PAGE',
    LoginAPOtpSent: 'A&P_OTP_SENT',
    LoginApVerifiedOtp: 'A&P_OTP_VERIFIED',
    LoginApVerifiedOtpError: 'A&P_OTP_VERIFIED_ERROR',

    //A&P
    apDataRequested: 'A&P_INFO_PAGE_REQUEST_ACCESS',
    apDataRequestedCancel: 'ACCESS_PERSONAL_DATA_REQUESTED_CANCEL',
    apRequestPageCancel: 'A&P_INFO_PAGE_CANCEL',
    apDownloadPDF: 'A&P_DOWNLOAD_PDF',
    apDownloadXML: 'A&P_DOWNLOAD_XML',
    apDownloadReadMore: 'A&P_MANAGE_CONTINUE',

    //my account login 
    landOnLoginPage: 'LAND_ON_LOGIN_PAGE',
    loginOTPSent : 'LOGIN_OTP_SENT',
    loginOTPVerified: 'LOGIN_OTP_VERIFIED',
    loginOTPVerifiedError: 'LOGIN_OTP_VERIFIED_ERROR',
    landOnMessagePage: 'LAND_ON_MESSAGE_PAGE',

  

    // subscription 
    newsletterEditOptin: 'NEWSLETTER_EDIT_OPTIN',
    newsletterEditOptout: 'NEWSLETTER_EDIT_OPTOUT',
    directMailEditOptin: 'DIRECT_MAIL_EDIT_OPTIN',
    directMailEditOptout: 'DIRECT_MAIL_EDIT_OPTOUT',
    updateSubscriptionCancelled: 'UPDATE_SUBSCRIPTION_CANCELLED',
    UpdateSubscriptionEdit: 'UPDATE_SUBSCRIPTION_EDIT',

    // dashboard 
    manageServiceLink: 'MANAGE_SERVICES_LINK',
    landOnOverviewPage: 'LAND_ON_OVERVIEW_PAGE',

    //ap survey

    apSurveySubmit: 'A&P_SURVEY_SUBMIT',
    apSurveyCancel: 'A&P_SURVEY_CANCEL',
    apSurveyLink: 'A&P_SURVEY_SURVEYLINK',

    //cic survey
    cicSurveySubmit: 'CIC_SURVEY_SUBMIT',
    cicSurveyCancel: 'CIC_SURVEY_CANCEL',

});

export const EVENTOUTCOME = Object.freeze({
    success: 'SUCCESS',
    failure: 'FAILURE'
});

export const TRAFFICSOURCE = Object.freeze({
    csp: 'CSP',
    apLogin: 'A&P_LOGIN',
    myAccount: 'MY_ACCOUNT'
});

export const CUSTOMERTYPE = Object.freeze({
    guest: 'z000',
})


export const brandNameFromID = Object.freeze({
    "0": "HM",
    "1": "COS",
    "2": "MONKI",
    "3": "WEEKDAY",
    "4": "CHEAPMONDAY",
    "5": "STORIES",
    "6": "ARKET"
})

export const countryNameFromID = Object.freeze(
    {
        "AD": "Andorra",
        "AR": "Argentina",
        "AS": "SamoaAmerica",
        "AT": "Austria",
        "AU": "Australia",
        "BB": "Barbados",
        "BD": "Bangladesh",
        "BE": "Belgium",
        "BG": "Bulgaria",
        "BH": "Bahrain",
        "BN": "BruneiDarussalam",
        "BT": "Bhutan",
        "CA": "Canada",
        "CH": "Switzerland",
        "CL": "Chile",
        "CN": "China",
        "CK": "CookIslands",
        "CR": "CostaRica",
        "CY": "Cyprus",
        "CZ": "CzechRepublic",
        "DE": "Germany",
        "DK": "Denmark",
        "EC": "Ecuador",
        "EE": "Estonia",
        "TO": "Tonga",
        "ES": "Spain",
        "KN": "SaintKitts&Nevis",
        "SR": "Suriname",
        "FI": "Finland",
        "FJ": "Fiji",
        "FM": "Micronesia",
        "FO": "FaroeIslands",
        "FR": "France",
        "GB": "UnitedKingdom",
        "GE": "Georgia",
        "GG": "Guernsey",
        "GI": "Gibraltar",
        "GL": "Greenland",
        "GR": "Greece",
        "GT": "Guatemala",
        "GU": "Guam",
        "HN": "Honduras",
        "HR": "Croatia",
        "HU": "Hungary",
        "ID": "Indonesia",
        "IE": "Ireland",
        "IL": "Israel",
        "IN": "India",
        "IS": "Iceland",
        "IT": "Italy",
        "JE": "Jersey",
        "JM": "Jamaica",
        "JP": "Japan",
        "KE": "Kenya",
        "KI": "Kiribati",
        "KH": "Cambodia",
        "KR": "SouthKorea",
        "LI": "Liechtenstein",
        "LT": "Lithuania",
        "LU": "Luxembourg",
        "LV": "Latvia",
        "MA": "Morocco",
        "MH": "MarshallIslands",
        "MN": "Mongolia",
        "MP": "NorthernMarianaIslands",
        "MV": "Maldives",
        "MX": "Mexico",
        "MY": "Malaysia",
        "NC": "NewCaledonia",
        "NG": "Nigeria",
        "NL": "Netherlands",
        "NO": "Norway",
        "NZ": "NewZealand",
        "PE": "Peru",
        "PF": "FrenchPolynesia",
        "PH": "Philippines",
        "PL": "Poland",
        "PR": "PuertoRico",
        "PT": "Portugal",
        "PW": "Palau",
        "RO": "Romania",
        "RS": "Serbia",
        "RU": "Russia",
        "SA": "Saudi Arabia",
        "SE": "Sweden",
        "SG": "Singapore",
        "SI": "Slovenia",
        "SK": "Slovakia",
        "SM": "SanMarino",
        "SV": "ElSalvador",
        "TH": "Thailand",
        "TR": "Turkey",
        "TW": "Taiwan",
        "US": "USA",
        "AE": "UnitedArabEmirates",
        "UY": "Uruguay",
        "VN": "Vietnam",
        "WF": "Wallis&Futuna",
        "WS": "Samoa",
        "WW": "Global",
        "ZA": "SouthAfrica",
        "SB": "SolomonIslands",
        "HK": "HongKong",
        "MO": "Macau"
    }
)


export const commonConstants = Object.freeze({
    OS_UNKOWN: 'Mobile Unknown',
    OS_ANDROID: 'Mobile Android',
    OS_IOS: 'Mobile IOS',
    TAB: 'Tab',
    DESKTOP: 'Desktop',
    UNKNOWN: 'Unknown',
})

export const cicConstants = Object.freeze({

    cicOtpFlow: 'CICOTPFLOW',
    cicSurveyFlow: 'CICSURVEY',
    cicVerificationFlow: 'CICVERIFICATIONFLOW'

})

export const otpStatus = Object.freeze({
    wrongOtp: "002",
    correctOtp: '001',
    alreadyVerified: '006',
})

 // temprory data will discuss with suman and remove from payload
 export const labelMappings = {
    contactcsifntrequestLabel1: 'Verification Email',
    contactcsifntrequestLabel: 'Verification Email',
    custconsentcicLabel: 'CIC with OTP',
    reqcustdeleteLabel: 'CIC with OTP',
    reqcustcodeLabel: 'CIC with OTP',
    codeexpiryLabel: 'CIC with OTP',
    accntdeletespanLabel: 'CIC with OTP',
    custcicnotifyLabel: 'CIC with OTP',
    custconfirmLabel: 'Lightboxes',
    msgsentwithaddressLabel: 'Verification Email',
};

export const APRequestStatusValue = Object.freeze({
    PENDING: "pending",
    INPROGRESS: "inprogress",
    CLOSED: "closed",
    WARNING: "warning",
    SUCCESS: "success"
});





