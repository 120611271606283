// import { Accordion } from '@material-ui/core'
import React, { useState, useContext } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as qs from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import { getkeyvalueText } from '../../../../util/helperfunctions'
import { Accordion, BodyText, InlineLink, HeadingSmall,MetaText } from '@hm-group/fabric-components'
import { UserContext } from '../../../../context/userContext'

const AccordionSection = () => {
  const [isshowkey, setisshowkey] = useState(qs.parse(useLocation().search).showkeys)
  const {lslKeyValue} = useContext(UserContext)

  return (
    <div>
      <Accordion
        isExpanded="false"
        accordionItems={[
          {
            content: (
              <>
               <BodyText></BodyText>
              <BodyText className="expanded__text" Tag="p">
                {getkeyvalueText('LandingPage', 'abtH&MgrpHeadercreatedmpdLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                <BodyText className="expanded__text" Tag="p">
                {
                  getkeyvalueText(
                    'LandingPage',
                    'abtH&MgrpHeadergrpbrandsLabel',
                    isshowkey,
                    lslKeyValue.lslKeyData,
                  ).split('{link}')[0]
                }
                <InlineLink target="_blank" rel="noreferrer" href="https://hmgroup.com/">
                  www.hmgroup.com​
                </InlineLink>
                {
                  getkeyvalueText(
                    'LandingPage',
                    'abtH&MgrpHeadergrpbrandsLabel',
                    isshowkey,
                    lslKeyValue.lslKeyData,
                  ).split('{link}')[1]
                }
                </BodyText>
                {/* <BodyText className="expanded__text">
                {
                  getkeyvalueText(
                    'LandingPage',
                    'abtH&MgrpHeadergrpbrandsLabel',
                    isshowkey,
                    lslKeyValue.lslKeyData,
                  ).split('{link}')[1]
                }
                </BodyText> */}
                <BodyText className="expanded__text">
                {getkeyvalueText('LandingPage', 'missengagementsLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                {/* <br />
                <br /> */}
                <MetaText Tag="p" alignment="start">
                {getkeyvalueText('LandingPage', 'abtH&MgrpHeaderafoundLabel', isshowkey, lslKeyValue.lslKeyData)}
                </MetaText>
              </>
            ),
            heading: (
              <BodyText Tag="p" >
                {getkeyvalueText('LandingPage', 'abtH&MgrpHeader', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '1',
          },
          {
            content: (
              <>
              <BodyText></BodyText>
              <BodyText className="expanded__text">
                {getkeyvalueText('LandingPage', 'whticandoHeaderonlinejourneyLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                <BodyText className="expanded__text">
                {getkeyvalueText(
                  'LandingPage',
                  'whticandoHeadercontactprivacyteamsLabel',
                  isshowkey,
                  lslKeyValue.lslKeyData,
                )}
              </BodyText>
              </>
            ),
            heading: (
              <BodyText Tag="p" >
                {getkeyvalueText('LandingPage', 'whticandoHeader', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '2',
          },
          {
            content: (
              <BodyText className="expanded__text">
                {getkeyvalueText(
                  'LandingPage',
                  'responsibledataHeaderprimarycompanyLabel',
                  isshowkey,
                  lslKeyValue.lslKeyData,
                )}
              </BodyText>
            ),
            heading: (
              <BodyText Tag="p" >
                {getkeyvalueText('LandingPage', 'responsibledataHeader', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '3',
          },
          {
            content: (
              <>
              <BodyText></BodyText>
              <BodyText className="expanded__text">
                {getkeyvalueText('LandingPage', 'dataprivacyHeaderprinciplesLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                <BodyText className="expanded__text">
                {getkeyvalueText('LandingPage', 'dataprivacyHeaderlawfuluseLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                <BodyText className="expanded__text">
                {getkeyvalueText(
                  'LandingPage',
                  'dataprivacyHeaderlegitmatepurposeLabel',
                  isshowkey,
                  lslKeyValue.lslKeyData,
                )}
                </BodyText>
                <BodyText className="expanded__text">
                {getkeyvalueText('LandingPage', 'dataprivacyHeaderretaindataLabel', isshowkey, lslKeyValue.lslKeyData)}
                </BodyText>
                <BodyText className="expanded__text">
                {getkeyvalueText(
                  'LandingPage',
                  'dataprivacyHeadersecuritymeasuresLabel',
                  isshowkey,
                  lslKeyValue.lslKeyData,
                )}
              </BodyText>
              </>
            ),
            heading: (
              <BodyText Tag="p" >
                {getkeyvalueText('LandingPage', 'dataprivacyHeader', isshowkey, lslKeyValue.lslKeyData)}
              </BodyText>
            ),
            id: '4',
          },
        ]}
        defaultId="0"
        onClick={function noRefCheck() {}}
        shouldCollapseSibling
        variant="fill"
      />
    </div>
  )
}

export default AccordionSection
