import React, { useContext, useState, useCallback } from "react";
import { GetCSEmailHtml, getBrandImagebyID, getCountryNamebyCode, getDisplayBrandNamebyID, getkeyvalueText, isCentraMarket, retrievePrivacyLink } from "../../util/helperfunctions";
import ModalComponent from "../Modal/ModalComponent";
import { EVENTACTION, EVENTOUTCOME, EVENTTYPE, PAGE, brandNameFromID, cicConstants } from "../../util/constants";
import { CICVerification } from "../../ModalComponents/CICVerification/CICVerification";
import { CICOtpVerification } from "../../ModalComponents/CICOtpVerification/CICOtpVerification";
import CICSurveyComponent from "../../ModalComponents/CICSurvey/CICSurveyComponent";
import { useHistory } from "react-router-dom";
import { AnalyticsContext } from "../../context/analyticsContext";
import { BodyText, InlineLink, HeadingLarge, PrimaryButton, SecondaryButton } from "@hm-group/fabric-components";
import "./DeletePersonalData.scss";
import { CustomerContext } from "../../context/customerDetailsContext";
import { UserContext } from "../../context/userContext";



export const DeletePersonalData = () => {
    const { analyticsData, updateAnalyticsData } = useContext(AnalyticsContext);
    const {lslKeyValue} = useContext(UserContext);
    const { customer } = useContext(CustomerContext);


    const {
        businessPartnerId,
        clubRole,
        emailAddress,
        corporateBrandId,
        countryCode,
        onlineOMSCustomerIdentifier
      } = customer
    const { lslKeyData } = lslKeyValue;
    const history = useHistory();
    const islslKey = false // TODO: Need to check this
 

    const [openModal, setOpenModal] = useState(false)

    const [childType, setChildType] = useState('')

    // Analytics update function
  const updateAnalytics = (  eventAction,eventOutcome) => {
    updateAnalyticsData({
      ...analyticsData,
      managedCountry: countryCode,
      managedBrand: corporateBrandId,
      eventType: EVENTTYPE.cicVerification,
      page: PAGE.cicInfoPage,
      eventAction,
      eventOutcome,
      managedBPID: businessPartnerId,
      managedCustomerType: clubRole,
    });
  };


    const onClose = useCallback((type) => {
        switch (type) {
            case cicConstants.cicOtpFlow:
                setOpenModal(false);
                break;

            case cicConstants.cicSurveyFlow:
                setOpenModal(false);
                sendCICAnalyticsData(EVENTACTION.cicSurveyCancel, EVENTOUTCOME.success)
                history.push({ pathname: '/dashboard' })
                break;

            default:
                setOpenModal(false);

        }

    }, [sendCICAnalyticsData])

    const sendCICAnalyticsData = useCallback(
        (eventAction, eventOutcome) => {
            updateAnalyticsData({
                ...analyticsData,
                managedCountry: countryCode,
                managedBrand: corporateBrandId,
                eventType: EVENTTYPE.cicSurvey,
                page: PAGE.cicSurveyPage,
                eventAction,
                eventOutcome,
                managedBPID: businessPartnerId,
                managedCustomerType: clubRole
            })
        }, [analyticsData, updateAnalyticsData, countryCode, corporateBrandId, businessPartnerId, clubRole]
    )

    const handleCicContinue = (type) => {
        setChildType(type)
    }

    const getChildrenComponent = useCallback((type) => {
        switch (type) {
            case cicConstants.cicOtpFlow:
                return <CICVerification handleCicContinue={handleCicContinue} onClose={onClose} payloadData = {{userName: emailAddress , country : countryCode, brand : brandNameFromID[corporateBrandId], clubRole : clubRole, businessPartnerId : businessPartnerId, }}/>

            case cicConstants.cicVerificationFlow:
                return <CICOtpVerification handleCicContinue={handleCicContinue} onClose={onClose} payloadData = {{userName: emailAddress , country : countryCode, brand : brandNameFromID[corporateBrandId], clubRole : clubRole, businessPartnerId : businessPartnerId, onlineOMSCustomerIdentifier : onlineOMSCustomerIdentifier}}/>

            case cicConstants.cicSurveyFlow:
                return <CICSurveyComponent onClose={onClose} payloadData={{ bpId: businessPartnerId, country : countryCode, brand: corporateBrandId, emailId: emailAddress }} sendCICAnalyticsData={sendCICAnalyticsData} />
        }
    }, [childType])






    const handlePrivacyAction = () => {
        window.open(retrievePrivacyLink(corporateBrandId, countryCode, lslKeyValue), "_blank")
    }

    return (

        <div className="parent-container spacing">
            <div className="brand__img spacing">
                <img className="logoImage" src={getBrandImagebyID(corporateBrandId)} alt="Brand Logo" />
            </div>
            <div>
                <HeadingLarge Tag="h1" className="headingText">
                    {getkeyvalueText(
                        'deletePersonalData',
                        'requestdeletiondataLabel',
                        islslKey,
                        lslKeyValue.lslKeyData,
                    )
                        .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                        .replace(
                            '{Market}',
                            `${getCountryNamebyCode(countryCode, islslKey, lslKeyData)}`,
                        )}
                </HeadingLarge>

            </div>


            <div>
                <BodyText Tag="p">
                    {getkeyvalueText('deletePersonalData', 'fulldataerasureLabel', islslKey, lslKeyData)
                        .replace('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                        .replace('{Market}', `${getCountryNamebyCode(countryCode, islslKey, lslKeyData)}`)}
                </BodyText>
                {isCentraMarket(brandNameFromID[corporateBrandId], countryCode) ? (
                    <BodyText Tag="p">
                        {getkeyvalueText('deletePersonalData', 'custdataretainLabel', islslKey, lslKeyData)
                            .replaceAll('{Brand}', `${getDisplayBrandNamebyID(corporateBrandId)}`)
                            .replace('{Market}', `${getCountryNamebyCode(countryCode, islslKey, lslKeyData)}`)}
                    </BodyText>
                ) : (
                    ''
                )}
                <BodyText Tag="p">
                    {getkeyvalueText('deletePersonalData', 'notloginLabel', islslKey, lslKeyData)}
                </BodyText>
                <BodyText Tag="p">
                    {getkeyvalueText('deletePersonalData', 'guestcheckoutLabel', islslKey, lslKeyData)}
                </BodyText>
                <BodyText Tag="p">
                    {
                        getkeyvalueText('deletePersonalData', 'details4rulesLabel', islslKey, lslKeyData).split(
                            '{Privacy Notice LINK}',
                        )[0]
                    }
                    <InlineLink
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            handlePrivacyAction(corporateBrandId, countryCode)
                        }}
                        onKeyDown={(event) => {
                            identifyEvent(event) && handlePrivacyAction(corporateBrandId, countryCode)
                        }}
                    >
                        {getkeyvalueText('LandingPage', 'privacynoticeLabel', islslKey, lslKeyData)}
                    </InlineLink>
                    {
                        getkeyvalueText('deletePersonalData', 'details4rulesLabel', islslKey, lslKeyData).split(
                            '{Privacy Notice LINK}',
                        )[1]
                    }
                </BodyText>

                <BodyText Tag="p">
                    {getkeyvalueText('Lightboxes', 'contactCStype1Label', islslKey, lslKeyData)}
                </BodyText>
                <GetCSEmailHtml
                    brand={brandNameFromID[corporateBrandId]}
                    country={countryCode}
                    lslKeyData={lslKeyData}
                    islslKey={islslKey}
                    align={'left'}
                />

            </div>

            <div className="actionButton ">
                <PrimaryButton onClick={() => {
                       setOpenModal(true)
                       setChildType(cicConstants.cicOtpFlow)
                       updateAnalytics(EVENTACTION.cicInfoPageContinue, EVENTOUTCOME.success)
                }}>
                    {getkeyvalueText('commonKey', 'continuebtn', islslKey, lslKeyData)}
                </PrimaryButton>
                <SecondaryButton onClick={
                    () => {
                        history.push({ pathname: '/ManageServices' })
                        updateAnalytics(EVENTACTION.cicInfoPageCancel, EVENTOUTCOME.success)
                    }
                } className="spacing">
                    {getkeyvalueText('commonKey', 'cancelbtnLabel', islslKey, lslKeyData)}
                </SecondaryButton>
            </div>
            <ModalComponent children={getChildrenComponent(childType)} onClose={onClose} openModal={openModal} />
        </div>
    );
};